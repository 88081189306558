import { computed, defineComponent, getCurrentInstance, onMounted, ref, watch } from '@vue/composition-api';
import Loader from '@/components/Loader.vue';
import { useMeta } from '@/shared/composables/useMeta';
import { SITE_URL } from '@/App.vue';
import { useGetAboutUsPageContentQuery } from '@/generated-types/graphql.types';
import { useStoryblokBridge } from '@/shared/composables/useStoryblokBridge';
export default defineComponent({
    components: {
        Loader
    },
    setup(_, context) {
        const { root } = context;
        const instance = getCurrentInstance();
        const { result, loading, refetch } = useGetAboutUsPageContentQuery({ enabled: true });
        const storyBase = ref(null);
        const story = computed({
            get: () => storyBase.value,
            set: (value) => (storyBase.value = value)
        });
        watch(() => result.value, () => {
            if (result.value) {
                storyBase.value = JSON.parse(result.value.get_about_us_cms_content.story).story;
            }
        }, { immediate: true });
        /**
         * Watch for locale change and refetch the home page content
         */
        watch(() => globalThis.$i18n.locale, () => {
            refetch();
        });
        if (window.location.search.includes('_storyblok')) {
            // load the bridge only inside of Storyblok Editor
            useStoryblokBridge(story);
            instance.$on('storyPublished', refetch);
        }
        onMounted(() => {
            useMeta({
                title: root.$i18n.t('meta.about_us.title_tag'),
                description: root.$i18n.t('meta.about_us.description_tag'),
                url: `${SITE_URL}/about-us`,
                noIndex: false
            });
        });
        return { story, loading };
    }
});
